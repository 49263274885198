import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";
import { formatMoney, getSavedQtyForAddon, priceFormat, qtyOptionExist, splitDescription } from "../../utils"
import { getAddonPlanById, getInternetPlanById, getMonthlyAndSingleTimeAddonPlan, getPlanData,getOptionalChannlesPrice } from "../../customfunction";
import MenuComponent from "./MenuComponent";
import LoaderComponent from "./LoaderComponent"
const AddonPlansComponent = ({ formData, setformData, setPage, plans, addons, page }: any) => {
    if (!plans || !addons || !formData) {
        return <LoaderComponent values="Loading....."/>;
    }
    const [total, setTotal] = useState(formData.internet_tv_total);
    // getMonthlyAndSingleTimeAddonPlan(addons, 'addon',formData);
    const phoneAddons = plans.homePhone
    const miscAddons = addons.misc
    const optionalAddons = [...phoneAddons, ...miscAddons]
    let seletedPlan = (formData?.plan_id != null) ? plans.internet.filter(obj => obj.id === formData?.plan_id)[0] : null; // getInternetPlanById(plans, formData?.plan_id) : null;
    let seletedTvPlan = (formData?.tv_plan != null) ? plans.tv.filter(obj => obj.id === formData?.tv_plan)[0] : null; // getAddonPlanById(addons, formData?.tv_plan) : null;
    let seletedAddonPlan: any = formData?.addons;
    const PlanChangeRadio = async (e: any) => {
        let value = String(e.target.value);
        if (e.target.checked) {
            seletedAddonPlan.push(value)
        } else {
            seletedAddonPlan.splice(seletedAddonPlan.indexOf(e.target.value), 1)
        }
        setformData({ ...formData, addons: seletedAddonPlan, optionalPlanTrigger: Math.floor(Math.random() * 1000000000) })
        // let allPlanData = [];
        // if (allId.length > 0) {
        //     for (let i = 0; i < allId.length; i++) {
        //         allPlanData.push(getAddonPlanById(addons, allId[i]));
        //     }
        // }
        // setformData({ ...formData, selectedAddonPlanData: allPlanData })
    }

    const CheckExistPlan = (id: any) => {
        if (seletedAddonPlan.indexOf(id) > -1) {
            return true;
        }
        return false;
    }

    // const CheckExistPlan = (id: any) => {
    //     if (seletedAddonPlan.indexOf(id) > -1) {
    //         return true;
    //     }
    //     return false;
    // }
    //
    // const getQuanty = (id: any) => {
    //     if (qtyOptionExist(getPlanData(addons), id)) {
    //         let qtyno = getSavedQtyForAddon(formData?.selectedAddonPlanData, id)
    //         if (qtyno) {
    //             return qtyno;
    //         }
    //         return 1
    //     }
    //     return "not"
    // }
    //
    // const changeQty = (e: any, id: any, type: any) => {
    //     let qtys: any = getQuanty(id)
    //     if (type == "add") {
    //         qtys = Number(qtys) + 1
    //     } else {
    //         qtys = qtys > 1 ? Number(qtys) - 1 : 1
    //     }
    //     let allIds = formData?.addons
    //     if (allIds.indexOf(id) == -1) {
    //         allIds.push(id)
    //         setformData({ ...formData, addons: allIds })
    //     }
    //     let selectdataNew: any = []
    //     optionalAddons.filter((addon: any) => {
    //         if (allIds.indexOf(addon.addon.id) > -1 && addon.addon.id == id) {
    //             let itemaddon = addon.addon
    //             itemaddon.quantity = qtys
    //             selectdataNew.push(itemaddon)
    //         } else {
    //             if (allIds.indexOf(addon.addon.id) > -1) {
    //                 selectdataNew.push(addon.addon);
    //             }
    //         }
    //     })
    //     setformData({ ...formData, selectedAddonPlanData: selectdataNew })
    // }

    const nextStep = async (e: any) => {
        setformData({ ...formData, total: total });
        setPage(8);
    }
    const prevStep = async (e: any) => {
        localStorage.setItem('pageChange','tvpage');
        if(formData?.tv_optional_plans.length>0 || formData?.tv_optional_single_plans.length>0){
            setPage(5);
        }else{
            setPage(5);
        }
    }


    // todo: draw inspiration from TvPlansOptional.tsx to obtain proper total (pretty much exact same code + addons pricing; ignore non_recurring charges)
    useEffect(() => {
        let totals = formData.internet_tv_total;
        optionalAddons.filter(item => formData.addons.includes(item?.billing_period[0].monthly ? item?.billing_period[0].monthly.api_name : item?.billing_period[0].non_recurring.api_name)).map((item: any) => {
            totals += item?.billing_period[0].monthly ? item?.billing_period[0].monthly.price : 0;
        })

        setTotal(totals);
    }, [formData])


    let quantityCheck: any = '';

    return (
      <>
          <Helmet>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <title>Plan – Purple Cow Internet 💜🐄</title>
          </Helmet>
          <main className="max-w-3xl mx-auto">
              <div className="body--lato height-100-btn-bottom">
                  <div>
                      {/* <div className="success-alert bg-primary-700 text-white">
                            Success. You're in our service area!
                        </div> */}
                      <div className="text-center logo-center">
                          <Link to="/">
                              <StaticImage
                                className="w-24"
                                layout="constrained"
                                src="../content/cow.png"
                                alt="Purple Cow Internet 💜🐄"
                              />
                          </Link>
                      </div>
                      <MenuComponent page={page} setPage={setPage}></MenuComponent>
                      <h3 className="mt-12 heading-h2">Pick optional add-ons.</h3>
                      <div className="selectPlan addon-plan">
                          {optionalAddons.map((item: any, _index: any) => {

                              // TODO: We have quantity limits for some of our addons? This is odd
                              // quantityCheck = item?.addon?.meta_data?.quantity
                              // let showQty = false
                              // if (quantityCheck == true && item?.addon?.type != 'on_off') {
                              //     showQty = true
                              // }

                              return (<label className="selectPlan__box" key={_index}>
                                  <input
                                    type="checkbox"
                                    name="selectPlan__"
                                    value={item?.billing_period[0].monthly ? item?.billing_period[0].monthly.api_name : item?.billing_period[0].non_recurring.api_name}
                                    checked={CheckExistPlan(item?.billing_period[0].monthly ? item?.billing_period[0].monthly.api_name : item?.billing_period[0].non_recurring.api_name)}
                                    onChange={PlanChangeRadio}
                                  />
                                  <div className="selectPlan__box-inner">
                                      <h3 className="h3">{item?.billing_period[0].monthly ? item?.billing_period[0].monthly.display_name : item?.billing_period[0].non_recurring.display_name}</h3>
                                      <h2 className="h2">
                                          ${item?.billing_period[0].monthly ? priceFormat(item?.billing_period[0].monthly.price) : priceFormat(item?.billing_period[0].non_recurring.price)}
                                      </h2>
                                      {item?.billing_period[0].monthly ? (<span className="plan-type">
                                            / monthly
                                        </span>) : ''
                                      }
                                      <p className="p4">
                                          <ul>
                                              {splitDescription(item?.description).map((line, index) => (
                                                <li key={index}>{line}</li>
                                              ))}
                                          </ul>
                                      </p>

                                      {/*{showQty ? (*/}
                                      {/*    <div className="increase-decrease">*/}
                                      {/*        <button*/}
                                      {/*            data-action="decrement"*/}
                                      {/*            className="increase-decrease__btn"*/}
                                      {/*        >*/}
                                      {/*            <span*/}
                                      {/*                className="mb-0.25 block leading-none qtydata"*/}
                                      {/*                onClick={(e) =>*/}
                                      {/*                    changeQty(e, item?.addon?.id, "sub")*/}
                                      {/*                }*/}
                                      {/*            >*/}
                                      {/*                −*/}
                                      {/*            </span>*/}
                                      {/*        </button>*/}
                                      {/*        <input*/}
                                      {/*            type="number"*/}
                                      {/*            className="md:text-basecursor-default flex items-center outline-none text-center increase-decrease__input qtydata"*/}
                                      {/*            name="custom-input-number"*/}
                                      {/*            value={getQuanty(item?.addon?.id)}*/}
                                      {/*        ></input>*/}
                                      {/*        <button*/}
                                      {/*            data-action="increment"*/}
                                      {/*            className="increase-decrease__btn"*/}
                                      {/*        >*/}
                                      {/*            <span*/}
                                      {/*                className="mb-0.5 block leading-none"*/}
                                      {/*                onClick={(e) =>*/}
                                      {/*                    changeQty(e, item?.addon?.id, "add")*/}
                                      {/*                }*/}
                                      {/*            >*/}
                                      {/*                +*/}
                                      {/*            </span>*/}
                                      {/*        </button>*/}
                                      {/*    </div>*/}
                                      {/*) : (*/}
                                      {/*    ""*/}
                                      {/*)}*/}
                                  </div>
                              </label>)
                          })}
                      </div>
                  </div>
                  <div className="steps-bottom-row">
                      {seletedPlan != null ? (
                        <div className="flex">
                            <div className="steps-bottom-row__month-payment-box">
                                <span className="p4">Monthly Payment</span>
                                <h3 className="h3">
                                    {formatMoney(total)}
                                </h3>
                            </div>
                            {/* <div className="steps-bottom-row__month-payment-box">
                                    <span className="p4">Installed in</span>
                                    <h3 className="h3">3-10 days</h3>
                                </div> */}
                        </div>
                      ) : ''
                      }
                      <div className="button-box">
                          <button className="btn btn-primary-outline" onClick={prevStep}>
                              {" "}
                              Back{" "}
                          </button>
                          <button
                            className="btn bg-primary-700 text-white" onClick={nextStep}
                          >
                              {" "}
                              Next{" "}
                          </button>
                      </div>
                  </div>
              </div>
          </main>
      </>
    )

}

export default AddonPlansComponent;
